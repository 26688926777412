import { Link } from 'react-router-dom';

export function ButtonPrimary({
  className, children, onclick,
}) {
  return (
    <button
      type="button"
      onClick={onclick}
      className={`font-bold font-dmsans py-3 transition-colors ease-in-out duration-[400ms] rounded-[10px] ${className}`}
    >
      {children}
    </button>
  );
}

export function LinkPrimary({
  href,
  className,
  children,
  target,
}) {
  return (
    <Link
      target={target}
      className={`${className} font-bold font-dmsans py-3 rounded-[10px] transition-colors ease-in-out duration-[400ms]`}
      to={href || '/'}
    >
      {children}
    </Link>
  );
}

export function LinkSecondary({
  href,
  className,
  children,
  target,
}) {
  return (
    <Link
      target={target}
      className={`font-bold font-dmsans py-3 rounded-[10px] bg-white hover:bg-[#F2F1FA] transition-colors ease-in-out duration-[400ms] text-[#514036] ${className}`}
      to={href || '/'}
    >
      {children}
    </Link>
  );
}
