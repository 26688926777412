import { Link } from 'react-router-dom';
import { LinkSecondary } from '../components/Buttons';
import { ServicesGrid } from '../components/ServicesGrid';
import { ServicesListDouble, ServicesListSimple } from '../components/ServicesList';
import ComplejoSlider from '../components/ComplejoSlider';
import { SERVICIOS } from '../constants';

const DESCRIPCION = [
  'Excelente ubicación en planta Alta. Sommier Queen Size , LCD 32”, Aire acondicionado.',
  'JACUZZI DOBLE: 1,80 x 1,60, ducha y Box de ducha.',
  'Heladera, Pava eléctrica, Microondas y vajilla (porcelana primera marca).',
  'Calefacción central.',
  'Balcón c/mesa y sillas de jardín.',
  'Amenities.',
];

const IMAGES = [
  { path: '/photos/suites/1.webp', alt: 'suites 1' },
  { path: '/photos/suites/2.webp', alt: 'suites 2' },
  { path: '/photos/suites/3.webp', alt: 'suites 3' },
  { path: '/photos/suites/4.webp', alt: 'suites 4' },
  { path: '/photos/suites/5.webp', alt: 'suites 5' },
  { path: '/photos/suites/6.webp', alt: 'suites 6' },
];

function Suites() {
  return (
    <main className="bg-[#FFF7E8] flex flex-col">
      <section className="relative overflow-clip items-center h-[80vh] lg:h-[100vh]">
        <video
          className="w-full h-full object-cover"
          type="video/mp4"
          autoPlay
          muted
          preload="true"
          loop
          playsInline
        >
          <source src="/videos/suites.mp4" type="video/mp4; codecs=hvc1" />
          <source src="/videos/suites.webm" type="video/webm; codecs=vp9" />
        </video>
        <div className="absolute flex items-center justify-center top-0 z-20 bg-gradient-to-t from-black/60 to-transparent w-full h-full">
          <h1 className="text-center text-[36px] lg:text-[44px] font-bold text-[#FAFAFF] font-timeless">
            Suites
          </h1>
        </div>
      </section>
      <section className="flex flex-col lg:flex-row px-6 container mx-auto mt-8 lg:mt-16 mb-8 lg:mb-20 justify-between">
        <div className="flex flex-col items-start w-full lg:w-1/2">
          <article className="flex flex-col items-start p-8 w-full lg:w-fit rounded-[10px] lg:rounded-xl bg-[#687530]">
            <span className="text-[28px] lg:text-4xl font-bold text-white font-timeless">
              Suites
            </span>
            <span className="text-base lg:text-[28px] lg:leading-9 font-dmsans font-normal text-white mt-8 lg:mt-10">
              Capacidad para
              <b> 2 personas.</b>
            </span>
            <LinkSecondary
              target="_blank"
              className="px-20 mt-8 w-full text-center lg:w-fit"
              href="https://hsol.com.ar/pms/bookingengine.php?idh=144"
            >
              Reservá aquí
            </LinkSecondary>
          </article>
        </div>
        <div className="flex flex-col items-center lg:items-start w-full lg:w-1/2 pt-8 lg:pt-0">
          <h2 className="text-4xl font-bold text-[#514036] font-timeless mb-8 lg:mb-6">
            Descripción
          </h2>
          <ServicesListSimple services={DESCRIPCION} />
        </div>
      </section>
      <section className="container px-6 mx-auto py-16">
        <ComplejoSlider images={IMAGES} />
      </section>
      <section className="container px-6 mx-auto flex flex-col items-center justify-center mt-8 mb-20">
        <h2 className="text-4xl font-bold text-[#514036] mb-16 font-timeless text-center">
          Conocé nuestros servicios
        </h2>
        <ServicesGrid />
      </section>
      <section className="container px-6 mx-auto flex flex-col lg:flex-row justify-center lg:justify-between gap-2 lg:gap-24 pb-8 lg:pb-16 font-dmsans">
        <ServicesListDouble services={SERVICIOS} />
      </section>
      <p className="text-base font-normal text-center font-dmsans">
        Conocé nuestras
        {' '}
        <Link to="/politicas-de-cancelacion" className="text-[#0047FF] font-bold underline">
          Políticas de cancelación
        </Link>
      </p>
    </main>
  );
}

export default Suites;
