import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import PHOTOS from '../photos';

function Galeria() {
  const [showModal, setShowModal] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);

  const toggleModal = () => setShowModal(!showModal);
  useEffect(() => {
    document.body.style.overflow = showModal ? 'hidden' : 'visible';
  }, [showModal]);

  return (
    <main className="flex flex-col bg-[#FFF7E8]">
      <section className="relative overflow-clip items-center h-[80vh] md:h-[100vh]">
        <img
          className="w-full h-full object-cover"
          src="/photos/complejo-1.webp"
          alt="nuestro complejo"
        />
        <div className="absolute flex items-center justify-center top-0 z-20 bg-gradient-to-t from-[#00000099] to-transparent bg-opacity-10 w-full h-full">
          <h1 className="text-center text-[36px] md:text-[44px] font-bold font-timeless text-[#FAFAFF]">
            Conocé nuestro complejo
          </h1>
        </div>
      </section>
      <section className="container grid px-6 mx-auto grid-cols-1 columns-1 md:grid-cols-2 lg:grid-cols-3 gap-6 py-16">
        {PHOTOS.map((photo, i) => (
          <button
            type="button"
            key={photo.src + 1}
            className="relative rounded-[10px] overflow-hidden bg-black group cursor-pointer"
            onClick={() => { setInitialSlide(i); toggleModal(); }}
          >
            <img
              key={photo.src}
              src={photo.src}
              alt=""
              className="aspect-[3/2] group-hover:scale-105 group-hover:opacity-80  transition-all duration-1000"
            />
            <div className="absolute inset-0 opacity-0 group-hover:opacity-20 transition-opacity duration-500" />
          </button>
        ))}
      </section>
      {showModal && <Modal toggleModal={toggleModal} initialSlide={initialSlide} />}
    </main>
  );
}

function Modal({ toggleModal, initialSlide }) {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        toggleModal();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="hidden md:block fixed h-screen w-full top-0 right-0 bg-black bg-opacity-90 z-50">
      <button
        type="button"
        className="absolute top-6 right-6"
        onClick={toggleModal}
      >
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="1.18506"
            y="0.580688"
            width="20.9513"
            height="1.6761"
            rx="0.838051"
            transform="rotate(45 1.18506 0.580688)"
            fill="white"
          />
          <rect
            x="16"
            y="1.76575"
            width="20.9513"
            height="1.6761"
            rx="0.838051"
            transform="rotate(135 16 1.76575)"
            fill="white"
          />
        </svg>
      </button>
      <div className="px-20 py-10 flex max-h-screen">
        <Swiper
          className="max-h-full"
          loop
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          initialSlide={initialSlide}
        >
          {PHOTOS.map((photo, i) => (
            <SwiperSlide key={photo.src}>
              <img
                className="h-full w-full object-contain"
                loading="eager"
                src={photo.src}
                alt={`galeria ${i}`}
              />
            </SwiperSlide>
          ))}
          <CustomNavigation />
        </Swiper>
      </div>
    </div>
  );
}

function CustomNavigation() {
  const swiper = useSwiper();
  return (
    <div className="hidden md:block">
      <button
        type="button"
        className="absolute top-1/2 -translate-y-1/2 left-8 z-50 hover:scale-[1.05] transition-transform ease-in-out duration-300"
        onClick={() => swiper.slidePrev()}
      >
        <svg
          className="rotate-180"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <circle cx="16" cy="16" r="16" fill="#514036" />
          <path
            d="M23.1071 16.7071C23.4976 16.3166 23.4976 15.6834 23.1071 15.2929L16.7431 8.92893C16.3526 8.53841 15.7194 8.53841 15.3289 8.92893C14.9384 9.31946 14.9384 9.95262 15.3289 10.3431L20.9858 16L15.3289 21.6569C14.9384 22.0474 14.9384 22.6805 15.3289 23.0711C15.7194 23.4616 16.3526 23.4616 16.7431 23.0711L23.1071 16.7071ZM9.59998 17H22.4V15H9.59998V17Z"
            fill="white"
          />
        </svg>
      </button>
      <button
        type="button"
        className="absolute top-1/2 -translate-y-1/2 right-8 z-10 hover:scale-[1.05] transition-transform ease-in-out duration-300"
        onClick={() => swiper.slideNext()}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <circle cx="16" cy="16" r="16" fill="#514036" />
          <path
            d="M23.1071 16.7071C23.4976 16.3166 23.4976 15.6834 23.1071 15.2929L16.7431 8.92893C16.3526 8.53841 15.7194 8.53841 15.3289 8.92893C14.9384 9.31946 14.9384 9.95262 15.3289 10.3431L20.9858 16L15.3289 21.6569C14.9384 22.0474 14.9384 22.6805 15.3289 23.0711C15.7194 23.4616 16.3526 23.4616 16.7431 23.0711L23.1071 16.7071ZM9.59998 17H22.4V15H9.59998V17Z"
            fill="white"
          />
        </svg>
      </button>
    </div>
  );
}

export default Galeria;
