import { useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import Layout from './pages/Layout';
import Home from './pages/Home';
import ApartPrivado from './pages/ApartPrivado';
import Cabanas from './pages/Cabanas';
import Suites from './pages/Suites';
import Aparts from './pages/Aparts';
import Galeria from './pages/Galeria';
import Merlo from './pages/Merlo';
import Servicios from './pages/Servicios';
import Contacto from './pages/Contacto';
import NotFound from './pages/_404';
import PoliticasDeCancelacion from './pages/PoliticasDeCancelacion';

export default function App() {
  return (
    <BrowserRouter scrollBehavior="auto">
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/complejo" element={<Home />} />
          <Route path="/apart-privado" element={<ApartPrivado />} />
          <Route path="/cabanas" element={<Cabanas />} />
          <Route path="/suites" element={<Suites />} />
          <Route path="/aparts" element={<Aparts />} />
          <Route path="/galeria" element={<Galeria />} />
          <Route path="/merlo" element={<Merlo />} />
          <Route path="/servicios" element={<Servicios />} />
          <Route path="/politicas-de-cancelacion" element={<PoliticasDeCancelacion />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
