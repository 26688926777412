const photosServices = [
  {
    src: '/photos/services/1.webp',
    width: 8,
    height: 12,
  },
  {
    src: '/photos/services/2.webp',
    width: 12,
    height: 8,
  },
  {
    src: '/photos/services/3.webp',
    width: 12,
    height: 8,
  },
  {
    src: '/photos/services/4.webp',
    width: 8,
    height: 12,
  },
  {
    src: '/photos/services/5.webp',
    width: 12,
    height: 8,
  },
  {
    src: '/photos/services/6.webp',
    width: 12,
    height: 8,
  },
  {
    src: '/photos/services/7.webp',
    width: 12,
    height: 8,
  },
];

export default photosServices;
