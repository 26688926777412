function ServicesListSimple({ services }) {
  return (
    <div className="flex flex-col md:flex-row justify-center gap-3 md:gap-24 font-dmsans">
      <ul className="flex flex-col gap-3 w-full">
        {services.map((service) => (
          <li className="flex flex-row gap-2 items-start" key={service}>
            <img src="/icons/check.svg" alt="Piscina climatizada" />
            <span>{service}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

function ServicesListDouble({ services }) {
  const halfServicesCount = Math.ceil(services.length / 2);
  const firstHalfServices = services.slice(0, halfServicesCount);
  const secondHalfServices = services.slice(halfServicesCount);

  return (
    <div className="flex flex-col md:flex-row justify-center gap-3 md:gap-24 font-dmsans w-full">
      <ul className="flex flex-col gap-3 w-full md:max-w-md">
        {firstHalfServices.map((service) => (
          <li className="flex flex-row gap-2 items-start" key={service}>
            <img src="/icons/check.svg" alt="Piscina climatizada" />
            <span>{service}</span>
          </li>
        ))}
      </ul>
      <ul className="flex flex-col gap-3 w-full md:max-w-md">
        {secondHalfServices.map((service) => (
          <li className="flex flex-row gap-2 items-start" key={service}>
            <img src="/icons/check.svg" alt="Piscina climatizada" />
            <span>{service}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export { ServicesListSimple, ServicesListDouble };
