import { Link } from 'react-router-dom';
import Gallery from 'react-photo-gallery';
import { ServicesRow } from '../components/ServicesGrid';
import photosServices from '../photosServices';
import ImageRenderedGalery from '../components/ImageRenderedGalery';
import ServiciosSlider from '../components/ServiciosSlider';
import { ServicesListDouble } from '../components/ServicesList';
import { SERVICIOS } from '../constants';

function Servicios() {
  return (
    <main className="flex flex-col bg-[#FFF7E8]">
      <section className="relative overflow-clip items-center h-[80vh] md:h-[100vh]">
        <img
          className="w-full h-full object-cover"
          src="/photos/header-servicios.webp"
          alt="nuestro complejo"
        />
        <div className="absolute flex flex-col items-center justify-end pb-10 md:justify-center gap-16 md:gap-24 top-0 z-20 bg-gradient-to-t from-[#00000099] to-transparent bg-opacity-10 w-full h-full">
          <h1 className="text-center block text-[36px] md:text-[44px] font-bold font-timeless text-[#FAFAFF]">
            Servicios
          </h1>
          <ServicesRow className="container mx-auto px-6 items-center md:absolute bottom-16 md:bottom-32" />
        </div>
      </section>
      <section className="container px-6 mx-auto flex flex-col items-center py-8 md:py-16">
        <h2 className="text-[28px] leading-9 md:text-4xl font-bold font-timeless px-6 text-[#514036] text-center mb-20">
          Conocé nuestros servicios
        </h2>
        <ServicesListDouble services={SERVICIOS} />
      </section>
      <span className="text-base font-normal text-center">
        Conocé nuestras
        {' '}
        <Link
          to="/politicas-de-cancelacion"
          className="text-[#0047FF] underline"
        >
          Políticas de cancelación
        </Link>
      </span>
      <section className="py-8 md:py-16 container px-6 mx-auto">
        <div className="hidden md:block">
          <Gallery
            photos={photosServices}
            renderImage={ImageRenderedGalery}
            margin={8}
          />
        </div>
        <div className="block md:hidden">
          <ServiciosSlider />
        </div>
      </section>
    </main>
  );
}

export default Servicios;
