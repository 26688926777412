import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import photosServices from '../photosServices';

export default function ServiciosSlider() {
  return (
    <Swiper
      className="h-full w-full servicios-slider"
      pagination={{
        clickable: true,
      }}
      modules={[Pagination]}
      slidesPerView="auto"
    >
      {photosServices.map((photo) => (
        <SwiperSlide key={photo.src}>
          <img className="object-cover h-full w-full block" src={photo.src} alt="imagen servicios" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
