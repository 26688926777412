const SERVICES = [
  {
    id: 1,
    iconSrcWhite: '/icons/wifi-white.svg',
    iconSrc: '/icons/wifi.svg',
    altText: 'icono wifi',
    description: 'Wifi',
  },
  {
    id: 2,
    iconSrcWhite: '/icons/cup-white.svg',
    iconSrc: '/icons/cup.svg',
    altText: 'icono taza',
    description: 'Desayuno',
  },
  {
    id: 3,
    iconSrcWhite: '/icons/king-size-white.svg',
    iconSrc: '/icons/king-size.svg',
    altText: 'icono cama',
    description: 'King size',
  },
  {
    id: 4,
    iconSrcWhite: '/icons/tv-white.svg',
    iconSrc: '/icons/tv.svg',
    altText: 'icono tv',
    description: 'TV',
  },
  {
    id: 5,
    iconSrcWhite: '/icons/pet_friendly-white.svg',
    iconSrc: '/icons/pet-friendly.svg',
    altText: 'icono huella de perro',
    description: 'Pet friendly',
  },
  {
    id: 6,
    iconSrcWhite: '/icons/kite-boys-white.svg',
    iconSrc: '/icons/kite-boys.svg',
    altText: 'icono barrilete',
    description: 'Plaza de juego para niños',
  },
  {
    id: 7,
    iconSrcWhite: '/icons/pool-white.svg',
    iconSrc: '/icons/pool.svg',
    altText: 'icono piscina',
    description: 'Piscina climatizada',
  },
  {
    id: 8,
    iconSrcWhite: '/icons/car-white.svg',
    iconSrc: '/icons/car.svg',
    altText: 'icono auto',
    description: 'Cocheras cubiertas',
  },
];

export function ServicesGrid() {
  return (
    <div className="grid grid-cols-2 justify-items-center md:grid-cols-4 gap-6 md:gap-12 w-full font-dmsans">
      {SERVICES.map((service) => (
        <ServiceItem
          key={service.id}
          iconSrc={service.iconSrc}
          altText={service.altText}
          description={service.description}
        />
      ))}
    </div>
  );
}

function ServiceItem({ iconSrc, altText, description }) {
  return (
    <div className="w-full h-[200px] p-6 bg-white rounded-[10px] shadow flex-col justify-center items-center gap-6 inline-flex">
      <img src={iconSrc} alt={altText} />
      <span className="self-stretch text-center text-neutral-700 text-sm font-medium font-dmsans leading-[18px]">
        {description}
      </span>
    </div>
  );
}

function ServiceItemTransparent({ iconSrc, altText, description }) {
  return (
    <div className="flex-col min-h-[75px] w-full items-center gap-3 flex">
      <img src={iconSrc} alt={altText} className="w-6 md:w-11 aspect-square" />
      <span className="text-center max-w-[100px] text-white text-sm font-medium font-dmsans leading-[18px]">
        {description}
      </span>
    </div>
  );
}

export function ServicesRow({ className }) {
  return (
    <div className={`grid grid-cols-4 md:grid-cols-8 justify-around items-center gap-3 w-full ${className}`}>
      {SERVICES.map((service) => (
        <ServiceItemTransparent
          key={service.id + 100}
          iconSrc={service.iconSrcWhite}
          altText={service.altText}
          description={service.description}
        />
      ))}
    </div>
  );
}
