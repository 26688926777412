import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { LinkPrimary } from '../components/Buttons';
import HeroSlider from '../components/HeroSlider';
import { ServicesGrid } from '../components/ServicesGrid';

function Home() {
  const [posts, setPosts] = useState([]);

  const getPost = async () => {
    const token = 'IGQWROb3ZADa0RHMmFVbVFLQkpqdFBnX0ZAxcTQ2bjN5WDZA0NzdLbTl5OENOeU9uNmJGWGlmTFBEQWF1dE1QN3JIUGJjb1VIdzIwYWlYdnBpMWdUR0c0cy1ORUtLdzk4bzdfQml2bHFITjdBc0tpb0xpcExGaG0tTTgZD';
    const igResponse = await fetch(
      `https://graph.instagram.com/v12.0/me/media?fields=id,media_url,permalink,media_type&limit=10&access_token=${token}`,
    );
    const igPost = await igResponse.json();

    const imagePosts = igPost.data.filter((post) => post.media_type === 'IMAGE');
    const lastThreePost = imagePosts.slice(0, 3);

    if (lastThreePost) {
      setPosts(lastThreePost);
    }
  };

  useEffect(() => {
    if (posts.length === 0) {
      getPost();
    }
  }, []);

  const REVIEWS = [
    {
      author_name: 'Pablo kamemu bracco',
      author_url: 'https://www.google.com/maps/contrib/110570482647452766406/reviews',
      language: 'es',
      original_language: 'es',
      profile_photo_url: 'https://lh3.googleusercontent.com/a-/ALV-UjWQc27AILMOHuNaUVVa2wjJ6I7A7hPNNmna0ckYObOcsu4=s128-c0x00000000-cc-rp-mo',
      rating: 5,
      relative_time_description: 'Hace 1 mes',
      text: 'Excelente la estadía, calidez y amabilidad, en cuanto a cercanía,muy buena ubicación,todo a mano. Muy satisfechos....para volver sin duda alguna...',
      time: 1691882229,
      translated: false,
    },
    {
      author_name: 'María del Luján Giulianelli',
      author_url: 'https://www.google.com/maps/contrib/109918787328555686125/reviews',
      language: 'es',
      original_language: 'es',
      profile_photo_url: 'https://lh3.googleusercontent.com/a-/ALV-UjWqT8Hc-BCS94-0iSyp40BvSMN79_n-xE3GyBI3Ss4YoJcT=s128-c0x00000000-cc-rp-mo-ba3',
      rating: 5,
      relative_time_description: 'Hace 2 meses',
      text: 'Pasamos unos días hermoso, el lugar es divino, muy buena atención, ubicación, comodidades. Súper recomendable',
      time: 1687957117,
      translated: false,
    },
    {
      author_name: 'Pilar Pilar',
      author_url: 'https://www.google.com/maps/contrib/106191821686224485470/reviews',
      language: 'es',
      original_language: 'es',
      profile_photo_url: 'https://lh3.googleusercontent.com/a/ACg8ocLjuoTnWhuqjBcWJdC1FKgou35EXg-GdL2OkO7n3VUd=s128-c0x00000000-cc-rp-mo-ba2',
      rating: 5,
      relative_time_description: 'Hace 1 mes',
      text: 'La ubicación es muy buena, ya que no hace falta usar vehículo para pasear por el centro. La atención exelente! Atentos en todo momento. Volveremos!!!',
      time: 1691193779,
      translated: false,
    },
  ];

  return (
    <main>
      <section className="relative flex justify-center">
        <HeroSlider />
        <div className="absolute z-10 inset-0 flex flex-col h-fit lg:h-full mt-[calc(50vh-80px)] lg:mt-0 items-center justify-center w-fit mx-auto text-[#FAFAFF]">
          <h1 className="text-center text-3xl lg:text-[44px] lg:leading-[44px] font-timeless font-bold leading-snug">
            Tus mejores días, son en
            <br />
            Don Gregorio
          </h1>
          <h2 className="text-center text-sm lg:text-base leading-normal mt-4 font-dmsans">
            Viví una experiencia increíble en las sierras de
            <br />
            San Luis, venite a Merlo.
          </h2>
          <LinkPrimary
            href="https://hsol.com.ar/pms/bookingengine.php?idh=144"
            target="_blank"
            className="bg-[#7C8944] flex justify-center hover:bg-[#4F5923] px-6 max-w-[260px] w-full text-[#FAFAFF] mt-11"
          >
            Reservá aquí
          </LinkPrimary>
        </div>
      </section>
      <section className="bg-[#FFF7E8]">
        <div className="container px-6 mx-auto flex flex-col items-center justify-center py-8 lg:py-16">
          <h2 className="text-[28px] lg:text-4xl font-timeless font-bold text-center text-[#514036]">
            Nuestro complejo
          </h2>
          <p className="leading-normal mt-8 font-dmsans max-w-prose text-center text-[#514036]">
            Disfurtá de un momento de tranquilidad en compañía de las sierras, en ambientes
            relajados, diseñados cuidadosamente mezclando rusticidad y modernidad. Con una
            inmejorable ubicación emplazado a 100mts. de Av. del Sol, principal vía turística, a
            100mts. del Casino Internacional Flamingo, a 1,3km de la terminal de Micros.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-16 gap-y-10 w-full mt-16 max-w-screen-lg">
            <ComplejoCard
              name="Apart Privado"
              href="/apart-privado"
              bg="photos/apart-privado/3.webp"
            />
            <ComplejoCard name="Aparts" href="/aparts" bg="/photos/aparts/3.webp" />
            <ComplejoCard name="Suites" href="/suites" bg="/photos/suites/2.webp" />
            <ComplejoCard
              name="Cabañas"
              href="/cabanas"
              bg="/photos/cabanas/1.webp"
            />
          </div>
        </div>
      </section>
      <section className="bg-[#FFF7E8]">
        <div className="container px-6 mx-auto flex flex-col items-center justify-center pb-16">
          <h2 className="text-[28px] lg:text-4xl font-timeless font-bold text-center text-[#514036]">
            Servicios
          </h2>
          <p className="leading-normal mt-8 font-dmsans max-w-prose text-center mb-16 text-[#514036]">
            El complejo cuenta con cabañas hasta 8 pax, apart hasta 4 pax y suits hasta 2 pax.Todas
            las unidades estas diseñadas con el máximo confort para su comodidad.
          </p>
          <ServicesGrid />
          <LinkPrimary
            href="/servicios"
            className="mt-16 text-white bg-[#7C8944] hover:bg-[#4F5923] max-w-[260px] w-full text-center px-6 rounded-[10px] font-dmsans font-bold z-10 relative"
          >
            Ver más
          </LinkPrimary>
        </div>
      </section>
      <section className="bg-[#687530]">
        <div className="container px-6 mx-auto flex flex-col items-center justify-center py-16">
          <h2 className="text-[28px] lg:text-4xl font-timeless font-bold text-center text-white">
            ¿Qué opinan nuestros huéspedes?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5 lg:gap-11 w-full mt-10 lg:mt-16 max-w-screen-lg">
            {REVIEWS.map((review) => (
              <ReviewCard
                avatar={review.profile_photo_url}
                review={review.text}
                stars={review.rating}
                name={review.author_name}
                key={review.time}
              />
            ))}
          </div>
          <a
            href="https://www.google.com/travel/hotels/entity/CgsIu6Ssq7CUoMijARAB/reviews"
            target="_blank"
            className="font-bold font-dmsans py-3 mt-10 lg:mt-16 text-[#514036] bg-white hover:bg-[#F2F1FA] transition-colors ease-in-out duration-[400ms] max-w-[260px] w-full text-center px-20 rounded-[10px] z-10 relative"
            rel="noreferrer"
          >
            Ver más
          </a>
        </div>
      </section>
      <section className="bg-[#514036]">
        <div className="container px-6 mx-auto flex flex-col items-center justify-center py-16">
          <h2 className="text-[28px] lg:text-4xl font-timeless font-bold text-center text-white">
            Seguinos en Instagram
          </h2>
          <a
            href="https://www.instagram.com/dongregoriocomplejo/"
            target="_blank"
            rel="noreferrer"
            className="text-white font-dmsans mt-4 underline underline-offset-2 font-bold"
          >
            @dongregoriocomplejo
          </a>
          <div className="lg:grid lg:grid-cols-3 xl:gap-11 w-full mt-10 lg:mt-16 lg:max-w-screen-lg relative flex gap-6 snap-x snap-mandatory overflow-x-auto pb-14">
            {posts.map((post) => (
              <InstagramCard src={post.media_url} href={post.permalink} key={post.id} />
            ))}
          </div>
        </div>
      </section>
      <section
        className="bg-cover bg-center bg-no-repeat py-16 lg:py-40"
        style={{ backgroundImage: 'url(/photos/merlo.webp)' }}
      >
        <div className="container px-6 mx-auto flex flex-col items-center justify-center py-16">
          <h2 className="text-4xl font-timeless font-bold text-white">¡Vení a Merlo!</h2>
          <p className="leading-normal mt-14 font-dmsans max-w-[27ch] text-center text-white text-lg">
            Una experiencia increíble en las sierras, ven a disfrutar con tu familia.
          </p>
          <LinkPrimary
            href="/merlo"
            className="mt-11 text-[#514036] bg-white hover:bg-[#F2F1FA] transition-colors ease-in-out duration-[400ms] max-w-[260px] w-full text-center px-20 rounded-[10px] font-dmsans font-bold z-10 relative"
          >
            Explorar
          </LinkPrimary>
        </div>
      </section>
    </main>
  );
}

function ComplejoCard({ name, href, bg }) {
  return (
    <article className="rounded-[10px] overflow-hidden w-full relative">
      <div
        style={{ backgroundImage: `url(${bg})` }}
        className="h-80 bg-cover flex flex-col justify-end p-8 items-center w-full"
      >
        <div className="absolute inset-0 bg-gradient-to-t from-[rgba(0,0,0,0.6)] to-transparent" />
        <h2 className="text-xl lg:text-[28px] font-timeless font-bold text-white text-center z-10 relative">
          {name}
        </h2>
        <Link
          to={href}
          className="text-white transition-colors ease-in-out duration-[400ms] bg-[#7C8944] hover:bg-[#4F5923] py-3 px-4 rounded-[10px] mt-3 font-dmsans font-bold z-10 relative"
        >
          Explorar
        </Link>
      </div>
    </article>
  );
}

function ReviewCard({
  name, review, stars, avatar,
}) {
  return (
    <article className="rounded-[10px] overflow-hidden w-full p-8 flex flex-col gap-6 bg-[#FFF7E8] text-[#101010]">
      <div className="flex items-center gap-1">
        {[...Array(5)].map((_, index) => (
          <svg
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={`${index < stars ? 'text-[#FFCB45]' : 'text-gray-400'}`}
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M8.52656 0.947266L11.4248 5.88867L17.0529 7.09893L13.216 11.3631L13.7961 17.0525L8.52656 14.7466L3.25701 17.0525L3.83709 11.3631L0.000244141 7.09893L5.62831 5.88867L8.52656 0.947266Z"
              fill="currentColor"
            />
          </svg>
        ))}
      </div>
      <p className="font-dmsans font-medium">
        “
        {review}
        ”
      </p>
      <div className="flex gap-4 items-center">
        <img src={avatar} alt={name} className="w-10 h-10 rounded-full object-cover" />
        <h3 className="text-sm font-dmsans font-bold">{name}</h3>
      </div>
    </article>
  );
}

function InstagramCard({ src, href }) {
  return (
    <Link
      to={href}
      target="_blank"
      className="rounded-[10px] overflow-hidden w-5/6 lg:w-full shadow-md bg-[#F2F1FA] snap-center shrink-0"
    >
      <img src={src} alt="" className="w-full h-full object-cover rounded-[5px]" />
    </Link>
  );
}

export default Home;
