import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function Header() {
  const [showDropdown, setShowDropdown] = useState(false);
  return (
    <header
      onMouseLeave={() => setShowDropdown(false)}
      className="absolute flex items-center justify-center z-50 h-[80px] w-full bg-[#514036]/90 backdrop-blur-sm text-white font-dmsans"
    >
      <nav className="flex flex-row w-full items-center justify-between px-6">
        <Link to="/">
          <img src="/logo-m.svg" width="110" height="70" alt="Logo don gregorio" />
        </Link>
        <div className="hidden lg:flex flex-row items-center justify-between gap-8">
          <button
            type="button"
            className="flex flex-row items-center gap-2 h-full cursor-default"
            onMouseEnter={() => setShowDropdown(true)}
          >
            <span className="text-base font-medium text-white">Complejo</span>
            <svg
              className={`${showDropdown ? '-rotate-180' : ''} transition-all duration-300`}
              width="15"
              height="8"
              viewBox="0 0 15 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.6665 1.0835L7.49984 6.91683L13.3332 1.0835"
                stroke="white"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          {showDropdown && (
          <div
            onMouseLeave={() => setShowDropdown(false)}
            className="absolute flex flex-col bg-white w-[190px] pb-4 rounded-b-2xl opacity-90 top-[100%]"
          >
            <Link
              className="flex text-sm font-medium text-[#514036] p-4 border-b border-[#E7E6F2] hover:bg-[#FFF7E8]"
              to="/apart-privado"
            >
              Apart privado
            </Link>
            <Link
              className="flex text-sm font-medium text-[#514036] p-4 border-b border-[#E7E6F2] hover:bg-[#FFF7E8]"
              to="/aparts"
            >
              Aparts
            </Link>
            <Link
              className="flex text-sm font-medium text-[#514036] p-4 border-b border-[#E7E6F2] hover:bg-[#FFF7E8]"
              to="/suites"
            >
              Suites
            </Link>
            <Link
              className="flex text-sm font-medium text-[#514036] p-4 border-b border-[#E7E6F2] hover:bg-[#FFF7E8]"
              to="/cabanas"
            >
              Cabañas
            </Link>
          </div>
          )}
          <Link
            onMouseEnter={() => setShowDropdown(false)}
            className="text-base font-medium text-white hover:underline underline-offset-4"
            to="/servicios"
          >
            Servicios
          </Link>
          <Link
            onMouseEnter={() => setShowDropdown(false)}
            className="text-base font-medium text-white hover:underline underline-offset-4"
            to="/galeria"
          >
            Galeria
          </Link>
          <Link
            onMouseEnter={() => setShowDropdown(false)}
            className="text-base font-medium text-white hover:underline underline-offset-4"
            to="/merlo"
          >
            Merlo
          </Link>
          <Link
            onMouseEnter={() => setShowDropdown(false)}
            className="text-base font-medium text-white hover:underline underline-offset-4"
            to="/contacto"
          >
            Contacto
          </Link>
          <Link
            onMouseEnter={() => setShowDropdown(false)}
            className="text-base font-medium py-3 px-6 rounded-xl bg-white hover:bg-[#F2F1FA] transition-colors ease-in-out duration-[400ms] text-[#514036] my-2"
            to="https://hsol.com.ar/pms/bookingengine.php?idh=144"
            target="_blank"
          >
            Reservá aquí
          </Link>
        </div>
        <MenuMobile />
      </nav>
    </header>
  );
}

function MenuMobile() {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownShow, setIsDropdownShow] = useState(true);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const showDropdown = () => {
    setIsDropdownShow(!isDropdownShow);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  });

  const ROOMS = [
    { name: 'Aparts Privados', link: '/apart-privado' },
    { name: 'Aparts', link: '/aparts' },
    { name: 'Suites', link: '/suites' },
    { name: 'Cabañas', link: '/cabanas' },
  ];

  return (
    <>
      <button className="lg:hidden" onClick={handleClick} type="button">
        {isOpen ? (
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="1.18506"
              y="0.580688"
              width="20.9513"
              height="1.6761"
              rx="0.838051"
              transform="rotate(45 1.18506 0.580688)"
              fill="white"
            />
            <rect
              x="16"
              y="1.76575"
              width="20.9513"
              height="1.6761"
              rx="0.838051"
              transform="rotate(135 16 1.76575)"
              fill="white"
            />
          </svg>
        ) : (
          <svg
            width="23"
            height="19"
            viewBox="0 0 23 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="22"
              y1="1.58087"
              x2="1.99824"
              y2="1.58087"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <line
              x1="22"
              y1="9.6167"
              x2="1.99824"
              y2="9.6167"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <line
              x1="22"
              y1="17.5805"
              x2="1.99824"
              y2="17.5805"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        )}
      </button>
      {isOpen && (
      <div className="lg:hidden h-[calc(100vh-80px)] w-full fixed top-full right-0 left-0 z-50 bg-white text-[#514036]">
        <div className="flex flex-col h-full overflow-hidden">
          <ul className="flex flex-col flex-grow overflow-y-auto">
            <li className="flex flex-col items-center border-b border-b-[#E7E6F2] relative">
              <button
                type="button"
                className="text-base text-[#514036] font-medium py-4 px-6 hover:bg-[#FFF7E8] flex w-full items-center"
                onClick={showDropdown}
              >
                Complejo
                <svg
                  className={`w-4 h-auto ml-auto transform transition-transform ${isDropdownShow ? 'rotate-0' : 'rotate-180'}`}
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                >
                  <path
                    d="M13 6.91669L7.16667 1.08335L1.33333 6.91669"
                    stroke="#514036"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              <ul
                className={`bg-white px-9 ${
                  isDropdownShow ? 'max-h-[calc(100vh-180px)] transition-all duration-300 ease-in-out overflow-y-auto' : 'max-h-0 overflow-hidden transition-all duration-300 ease-in-out'
                } w-full`}
              >
                {ROOMS.map((roomType) => (
                  <li key={roomType.name} className="border-b border-b-[#E7E6F2] w-full flex last:border-none">
                    <Link
                      className="text-base text-[#514036] font-normal hover:bg-[#FFF7E8] py-4 w-full"
                      to={roomType.link}
                      onClick={handleClick}
                    >
                      {roomType.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
            <li className="flex items-center border-b border-b-[#E7E6F2]">
              <Link
                className="text-base text-[#514036] font-medium py-4 px-6 w-full hover:bg-[#FFF7E8]"
                onClick={handleClick}
                to="/servicios"
              >
                Servicios
              </Link>
            </li>
            <li className="flex items-center border-b border-b-[#E7E6F2]">
              <Link
                className="text-base text-[#514036] font-medium py-4 px-6 w-full hover:bg-[#FFF7E8]"
                onClick={handleClick}
                to="/galeria"
              >
                Galería
              </Link>
            </li>
            <li className="flex items-center border-b border-b-[#E7E6F2]">
              <Link
                className="text-base text-[#514036] font-medium py-4 px-6 w-full hover:bg-[#FFF7E8]"
                onClick={handleClick}
                to="/merlo"
              >
                Merlo
              </Link>
            </li>
            <li className="flex items-center border-b border-b-[#E7E6F2]">
              <Link
                className="text-base text-[#514036] font-medium py-4 px-6 w-full hover:bg-[#FFF7E8]"
                onClick={handleClick}
                to="/contacto"
              >
                Contacto
              </Link>
            </li>
          </ul>
          <div className="flex w-full">
            <Link
              className="text-base font-medium py-4 px-6 text-white bg-[#687530] w-full"
              to="https://hsol.com.ar/pms/bookingengine.php?idh=144"
              onClick={handleClick}
              target="_blank"
            >
              Reserva aquí
            </Link>
          </div>
        </div>
      </div>

      )}
    </>
  );
}
