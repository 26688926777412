import { useState } from 'react';

async function sendForm(formData) {
  try {
    const response = await fetch('enviar-email.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(formData).toString(),
    });

    if (response.ok) {
      return true;
    }

    return false;
  } catch (e) {
    return false;
  }
}

function Contacto() {
  const [formData, setFormData] = useState({
    nombre: '',
    email: '',
    telefono: '',
    comentario: '',
  });
  const [emailState, setEmailState] = useState('start');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmailState('loading');

    try {
      const sended = await sendForm(formData);
      if (!sended) {
        return setEmailState('error');
      }

      return setEmailState('sended');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error al enviar el formulario:', error);
      return setEmailState('error');
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <main className="relative bg-center flex flex-col items-center pt-28 pb-16 lg:pt-40 lg:pb-20">
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: 'url("/photos/bg-contacto.webp")',
        }}
      />
      <div className="bg-gradient-to-t from-black/80 to-black/50 absolute inset-0" />
      <section className="flex flex-col relative container items-center justify-center px-6 mx-auto py-8 md:py-20">
        <h1 className="text-center text-white text-4xl lg:text-5xl font-timeless font-bold leading-snug">Contactanos</h1>
        {emailState !== 'sended' && emailState !== 'error' && <h2 className="text-center text-white font-dmsans font-normal mt-4 lg:mt-8">Envíe su consulta por medio del formulario de contacto</h2>}
        {emailState === 'sended' && <h2 className="text-center text-white font-dmsans font-normal mt-4 lg:mt-8">Su consulta se envio correctamente</h2>}
        {emailState === 'error' && <h2 className="text-center text-white font-dmsans font-normal mt-4 lg:mt-8">Ha ocurrido un error al intentar enviar su consulta, por favor intente nuevamente mas tarde</h2>}
        {emailState !== 'sended' && emailState !== 'error' && (
        <form onSubmit={handleSubmit} onChange={handleChange} className="flex flex-col gap-4 lg:gap-6 max-w-prose w-full mt-8 lg:mt-14">
          <div className="flex flex-col md:flex-row gap-8 w-full">
            <label className="flex text-white flex-col font-bold gap-1 font-dmsans w-full" htmlFor="nombre">
              Nombre
              <input type="text" className="bg-white font-dmsans focus:ring-[#514036] font-normal rounded-[10px] placeholder:text-[#ADABC3] text-[#514036] border-none py-4 px-6" name="nombre" placeholder="Juan Perez" required />
            </label>
            <label className="flex text-white flex-col font-bold gap-1 font-dmsans w-full" htmlFor="telefono">
              Teléfono
              <input type="text" name="telefono" className="bg-white font-dmsans focus:ring-[#514036] font-normal rounded-[10px] placeholder:text-[#ADABC3] text-[#514036] border-none py-4 px-6" placeholder="+54 11 1234-5678" required />
            </label>
          </div>
          <label className="flex text-white flex-col font-bold gap-1 font-dmsans" htmlFor="email">
            Email
            <input type="email" name="email" className="bg-white font-dmsans focus:ring-[#514036] font-normal rounded-[10px] placeholder:text-[#ADABC3] text-[#514036] border-none py-4 px-6" placeholder="juan.perez@ejemplo.com" required />
          </label>
          <label className="flex text-white flex-col font-bold gap-1 font-dmsans" htmlFor="comentario">
            Consulta
            <textarea name="comentario" className="bg-white font-dmsans focus:ring-[#514036] font-normal rounded-[10px] placeholder:text-[#ADABC3] text-[#514036] border-none py-4 px-6 h-full max-h-[250px] min-h-[100px]" placeholder="Hola, quiero consultar sobre..." required />
          </label>
          <button disabled={emailState === 'loading'} type="submit" className="bg-[#7C8944] hover:bg-[#4F5923] font-bold font-dmsans py-3 transition-colors ease-in-out duration-[400ms] rounded-[10px] px-6 lg:max-w-[260px] w-full text-[#FAFAFF] mt-4">Enviar consulta</button>
        </form>
        )}
      </section>
    </main>
  );
}

export default Contacto;
