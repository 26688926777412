function ImageRenderedGalery({ photo, margin }) {
  return (
    <img
      style={{
        margin, height: photo.height, width: photo.width,
      }}
      className="rounded-xl overflow-hidden object-cover"
      alt={photo.title}
      height={photo.height}
      width={photo.width}
      src={photo.src}
    />
  );
}

export default ImageRenderedGalery;
