import { Link } from 'react-router-dom';
import { LinkSecondary } from '../components/Buttons';
import { ServicesGrid } from '../components/ServicesGrid';
import {
  ServicesListDouble,
  ServicesListSimple,
} from '../components/ServicesList';
import ComplejoSlider from '../components/ComplejoSlider';
import { SERVICIOS } from '../constants';

const DESCRIPCION_PB = [
  'Dormitorio con sommier de 2 x1,80 (alternativa dos camas de 2x 0,90 m, Aire acondicionado, Led 32”',
  'Placard. Cocina totalmente equipada : Heladera con freezer, Microondas, Tostadora, Cocina con horno, Vajilla completa (porcelana primera marca)',
  '2 Baños con ducha.',
  'Living comedor sofá, Led 32”, Aire acondicionado.',
  'Calefacción central. Patio techado con mesa y sillas de Jardín.',
];

const DESCRIPCION_PA = [
  'Dormitorio en suite.',
  'Secador de pelo, Sommier de 2m x 1,80 m, Led 32”',
  'Ventilador de techo y Aire acondicionado.',
  'Placard y caja de Seguridad.',
  'Sala de estar con sofá cama (1paza) y Amplia terraza balcón con hermosa vista a la Sierras.',
];

const IMAGES = [
  { path: '/photos/cabanas/1.webp', alt: 'cabañas 1' },
  { path: '/photos/cabanas/2.webp', alt: 'cabañas 2' },
  { path: '/photos/cabanas/3.webp', alt: 'cabañas 3' },
  { path: '/photos/cabanas/4.webp', alt: 'cabañas 4' },
  { path: '/photos/cabanas/5.webp', alt: 'cabañas 5' },
  { path: '/photos/cabanas/6.webp', alt: 'cabañas 6' },
];

function Cabanas() {
  return (
    <main className="bg-[#FFF7E8] flex flex-col">
      <section className="relative overflow-clip items-center h-[80vh] lg:h-[100vh]">
        <video
          className="w-full h-full object-cover"
          type="video/mp4"
          autoPlay
          muted
          preload="true"
          loop
          playsInline
        >
          <source src="/videos/cabanas.mp4" type="video/mp4; codecs=hvc1" />
          <source src="/videos/cabanas.webm" type="video/webm; codecs=vp9" />
        </video>
        <div className="absolute flex items-center justify-center top-0 z-20 bg-gradient-to-t from-black/60 to-transparent w-full h-full">
          <h1 className="text-center text-[36px] lg:text-[44px] font-bold text-[#FAFAFF] font-timeless">
            Cabañas
          </h1>
        </div>
      </section>
      <section className="flex flex-col lg:flex-row px-6 container mx-auto mt-8 lg:mt-16 mb-8 lg:mb-20 justify-between">
        <div className="flex flex-col items-start w-full lg:w-1/2">
          <article className="flex flex-col items-start p-8 w-full lg:w-fit rounded-[10px] lg:rounded-xl bg-[#687530]">
            <span className="text-[28px] lg:text-4xl font-bold text-white font-timeless">
              Cabañas
            </span>
            <span className="text-base lg:text-[28px] lg:leading-9 font-dmsans font-normal text-white mt-8 lg:mt-10">
              Capacidad hasta
              <b> 8 personas.</b>
              <br />
              Cabañas de
              {' '}
              <b>
                100 m
                <sup>2</sup>
                .
              </b>
            </span>
            <LinkSecondary
              target="_blank"
              className="px-20 mt-8 w-full text-center lg:w-fit"
              href="https://hsol.com.ar/pms/bookingengine.php?idh=144"
            >
              Reservá aquí
            </LinkSecondary>
          </article>
        </div>
        <div className="flex flex-col items-center lg:items-start w-full lg:w-1/2 pt-8 lg:pt-0">
          <h2 className="text-4xl font-bold text-[#514036] font-timeless mb-8 lg:mb-6">
            Descripción PB
          </h2>
          <ServicesListSimple services={DESCRIPCION_PB} />
          <h2 className="text-4xl font-bold text-[#514036] font-timeless mb-8 lg:mb-6 pt-6">
            Descripción PA
          </h2>
          <ServicesListSimple services={DESCRIPCION_PA} />
        </div>
      </section>
      <section className="container px-6 mx-auto py-16">
        <ComplejoSlider images={IMAGES} />
      </section>
      <section className="container px-6 mx-auto flex flex-col items-center justify-center mt-8 mb-20">
        <h2 className="text-4xl font-bold text-[#514036] mb-16 font-timeless text-center">
          Conocé nuestros servicios
        </h2>
        <ServicesGrid />
      </section>
      <section className="container px-6 mx-auto flex flex-col lg:flex-row justify-center lg:justify-between gap-2 lg:gap-24 pb-8 lg:pb-16 font-dmsans">
        <ServicesListDouble services={SERVICIOS} />
      </section>
      <p className="text-base font-normal text-center font-dmsans">
        Conocé nuestras
        <Link
          to="/politicas-de-cancelacion"
          className="text-[#0047FF] font-bold underline"
        >
          Políticas de cancelación
        </Link>
      </p>
    </main>
  );
}

export default Cabanas;
