// eslint-disable-next-line import/prefer-default-export
export const SERVICIOS = [
  'Nuestra complejo cuenta con unidades pensadas para tu descanso y confort completo durante tu estadía.',
  'Cabañas con capacidad de hasta 6 pax.',
  'Aparts con capacidad de hasta 4 pax',
  'Un apart privado, con jardín privado.',
  'Suites con capacidad de hasta 2 pax.',
  'Piscina climatizada descubierta, baranda perimetral. reposeras. habilitada todo el año (horario verano 9hs a 20hs).',
  'Hermoso parque de 4.500m con flores, parral-glorieta y árboles frutales.',
  'Plaza para niños con juegos en madera.',
  'Cocheras cubiertas individual (1 por unidad).',
  'Con wifi a través de fibra óptica dentro de las unidades y en los espacios en común.',
  'TV por cable.',
  'Ropa de blanco y tallón de pileta.',
  'Servicio de mucama.',
  'Todas las unidades cuentan con practicuna con sus sabanas, frazada, sillita alta y bañerita.',
  'Todas las unidades cuentan con PARRILLA.',
  'Admitimos mascotas falderas (razas similares a caniche toy) con aviso anticipado y costo adicional para reservas en cabañas y apart. Por otras razas consultar antes de reservar.',
  'Desayuno opcional con costo adicional (consultar)',
  'Asesoramiento para el turismo en la ciudad, el entretenimiento, la gastronomía y el entorno local.',
  'Descuentos del 10% en los mejores restaurantes.',
];
