import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';

export default function HeroSlider() {
  return (
    <Swiper
      className="w-full max-h-[100vh] h-screen hero-home-slider"
      loop
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      modules={[Autoplay, Pagination]}
    >
      <SwiperSlide>
        <div className="relative">
          <img
            className="block object-cover w-full h-[100vh] lg:h-auto"
            src="/photos/head-home-1.webp"
            loading="eager"
            alt=""
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-black/20" />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="relative">
          <img
            className="block object-cover w-full h-[100vh] lg:h-auto"
            src="/photos/head-home-2.webp"
            loading="eager"
            alt=""
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-black/20" />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="relative">
          <img
            className="block object-cover w-full h-[100vh] lg:h-auto"
            src="/photos/head-home-3.webp"
            loading="eager"
            alt=""
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-black/20" />
        </div>
      </SwiperSlide>
      <CustomNavigation />
    </Swiper>
  );
}

function CustomNavigation() {
  const swiper = useSwiper();
  return (
    <div className="hidden lg:block">
      <button
        type="button"
        className="absolute top-1/2 -translate-y-1/2 left-8 z-50 hover:scale-[1.05] transition-transform ease-in-out duration-300"
        onClick={() => swiper.slidePrev()}
      >
        <svg
          className="rotate-180"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <circle cx="16" cy="16" r="16" fill="#514036" />
          <path
            d="M23.1071 16.7071C23.4976 16.3166 23.4976 15.6834 23.1071 15.2929L16.7431 8.92893C16.3526 8.53841 15.7194 8.53841 15.3289 8.92893C14.9384 9.31946 14.9384 9.95262 15.3289 10.3431L20.9858 16L15.3289 21.6569C14.9384 22.0474 14.9384 22.6805 15.3289 23.0711C15.7194 23.4616 16.3526 23.4616 16.7431 23.0711L23.1071 16.7071ZM9.59998 17H22.4V15H9.59998V17Z"
            fill="white"
          />
        </svg>
      </button>
      <button
        type="button"
        className="absolute top-1/2 -translate-y-1/2 right-8 z-10 hover:scale-[1.05] transition-transform ease-in-out duration-300"
        onClick={() => swiper.slideNext()}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <circle cx="16" cy="16" r="16" fill="#514036" />
          <path
            d="M23.1071 16.7071C23.4976 16.3166 23.4976 15.6834 23.1071 15.2929L16.7431 8.92893C16.3526 8.53841 15.7194 8.53841 15.3289 8.92893C14.9384 9.31946 14.9384 9.95262 15.3289 10.3431L20.9858 16L15.3289 21.6569C14.9384 22.0474 14.9384 22.6805 15.3289 23.0711C15.7194 23.4616 16.3526 23.4616 16.7431 23.0711L23.1071 16.7071ZM9.59998 17H22.4V15H9.59998V17Z"
            fill="white"
          />
        </svg>
      </button>
    </div>
  );
}
