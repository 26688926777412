import { Link, Outlet } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';

export default function Layout() {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
      <WhatsAppFloating />
    </>
  );
}

function WhatsAppFloating() {
  return (
    <Link
      className="fixed bottom-6 right-6 z-20 bg-[#00CD4C] flex justify-center items-center p-3 rounded-[42%]"
      to="https://wa.me/5492664007009?text=Hola!%20Quisiera%20realizar%20una%20consulta..."
      target="_blank"
    >
      <svg width="32" height="32" viewBox="0 0 42 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.292969 42.0601L1.97297 37.0551C2.53297 35.4451 2.98797 33.8001 3.61797 32.2251C3.78997 31.8893 3.87967 31.5174 3.87967 31.1401C3.87967 30.7628 3.78997 30.391 3.61797 30.0551C1.62802 26.3813 0.815903 22.1845 1.29173 18.0335C1.76755 13.8826 3.5083 9.97836 6.27797 6.85015C8.28872 4.55804 10.7981 2.75732 13.6134 1.58632C16.4286 0.415319 19.4748 -0.0947987 22.518 0.0951482C27.1304 0.247355 31.5419 2.01937 34.978 5.10015C38.2273 7.92831 40.4946 11.7153 41.453 15.9151C42.2533 19.3114 42.1568 22.8574 41.173 26.2051C39.9702 30.412 37.4108 34.1034 33.893 36.7051C31.5639 38.5191 28.8625 39.7955 25.9823 40.4429C23.102 41.0904 20.1143 41.0929 17.233 40.4501C15.4102 40.0836 13.6524 39.4465 12.018 38.5601C11.8921 38.5009 11.7546 38.4701 11.6155 38.4701C11.4763 38.4701 11.3389 38.5009 11.213 38.5601L1.83297 41.5001L0.292969 42.0601ZM5.61297 36.7751H5.92797L11.808 34.9202C12.0122 34.8508 12.2337 34.8508 12.438 34.9202C13.278 35.3401 14.083 35.8651 14.958 36.2151C18.4451 37.7467 22.3589 38.0068 26.018 36.9501C28.1702 36.3753 30.1859 35.3764 31.9469 34.0121C33.7079 32.6478 35.1787 30.9455 36.273 29.0051C38.4446 25.225 39.0853 20.7564 38.0632 16.5183C37.0411 12.2802 34.434 8.59488 30.778 6.22015C27.4677 4.07261 23.5139 3.14269 19.5934 3.58965C15.673 4.0366 12.0299 5.83262 9.28797 8.67015C7.04354 10.9621 5.51171 13.8555 4.87797 17.0001C4.37983 19.1561 4.32461 21.3907 4.71566 23.5685C5.10672 25.7464 5.93591 27.8222 7.15297 29.6701C7.35055 29.9976 7.45498 30.3727 7.45498 30.7551C7.45498 31.1376 7.35055 31.5127 7.15297 31.8401C6.69797 33.3801 6.20797 35.0601 5.61297 36.7751Z" fill="#ffffff" />
        <path d="M32.0002 26.4148C31.9789 27.1599 31.7474 27.8838 31.3325 28.503C30.9176 29.1223 30.3362 29.6117 29.6552 29.9148C28.9483 30.3011 28.166 30.5293 27.3623 30.5837C26.5586 30.6381 25.7527 30.5173 25.0002 30.2298C23.5591 29.7071 22.1456 29.1114 20.7652 28.4448C18.9818 27.5001 17.3911 26.2299 16.0752 24.6998C14.5822 23.0577 13.3006 21.2352 12.2602 19.2748C11.4798 17.9162 11.1148 16.3587 11.2102 14.7948C11.2822 13.9873 11.5254 13.2044 11.9237 12.4983C12.322 11.7922 12.8663 11.1791 13.5202 10.6998C13.8217 10.4957 14.1719 10.3749 14.5352 10.3498C14.9778 10.3141 15.4226 10.3141 15.8652 10.3498C16.027 10.3703 16.1794 10.4375 16.3037 10.5431C16.428 10.6488 16.5189 10.7884 16.5652 10.9448C16.9152 11.7498 17.1952 12.5548 17.5102 13.3948C17.8252 14.2348 17.8952 14.5148 18.1402 15.0398C18.3556 15.3291 18.4599 15.6861 18.4342 16.0458C18.4085 16.4055 18.2545 16.7441 18.0002 16.9998C17.6632 17.44 17.2885 17.8499 16.8802 18.2248C16.7308 18.3425 16.6303 18.5113 16.598 18.6987C16.5657 18.8861 16.6039 19.0789 16.7052 19.2398C17.6528 20.9608 18.9106 22.4915 20.4152 23.7548C21.4937 24.5637 22.6696 25.234 23.9152 25.7498C24.0442 25.8679 24.2128 25.9333 24.3877 25.9333C24.5626 25.9333 24.7311 25.8679 24.8602 25.7498L26.6102 23.7548C26.9952 23.3348 27.1702 23.2298 27.6602 23.4748L31.9652 25.7148C32.0234 25.8213 32.0568 25.9396 32.0629 26.0608C32.0689 26.182 32.0475 26.303 32.0002 26.4148Z" fill="#ffffff" />
      </svg>
    </Link>
  );
}
