const PHOTOS = [
  {
    src: '/photos/galeria/1.webp',
  },
  {
    src: '/photos/galeria/2.webp',
  },
  {
    src: '/photos/galeria/3.webp',
  },
  {
    src: '/photos/galeria/4.webp',
  },
  {
    src: '/photos/galeria/5.webp',
  },
  {
    src: '/photos/galeria/6.webp',
  },
  {
    src: '/photos/galeria/7.webp',
  },
  {
    src: '/photos/galeria/8.webp',
  },
  {
    src: '/photos/galeria/9.webp',
  },
  {
    src: '/photos/galeria/10.webp',
  },
  {
    src: '/photos/galeria/11.webp',
  },
  {
    src: '/photos/galeria/12.webp',
  },
  {
    src: '/photos/galeria/13.webp',
  },
  {
    src: '/photos/galeria/14.webp',
  },
  {
    src: '/photos/galeria/15.webp',
  },
  {
    src: '/photos/galeria/16.webp',
  },
  {
    src: '/photos/galeria/17.webp',
  },
  {
    src: '/photos/galeria/18.webp',
  },
  {
    src: '/photos/galeria/19.webp',
  },
  {
    src: '/photos/galeria/20.webp',
  },
  {
    src: '/photos/galeria/21.webp',
  },
  {
    src: '/photos/galeria/22.webp',
  },
  {
    src: '/photos/galeria/23.webp',
  },
  {
    src: '/photos/galeria/24.webp',
  },
  {
    src: '/photos/galeria/25.webp',
  },
];

export default PHOTOS;
