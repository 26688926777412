import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';

export default function ComplejoSlider({ images }) {
  return (
    <Swiper
      className="w-full complejo-slider"
      pagination={{ clickable: true }}
      modules={[Pagination]}
      slidesPerView="auto"
      spaceBetween={20}
    >
      {images && images.map((img) => (
        <SwiperSlide>
          <img className="w-full h-full block object-cover" src={img.path} alt={img.alt} />
        </SwiperSlide>
      ))}
      <CustomNavigation />
    </Swiper>
  );
}

function CustomNavigation() {
  const swiper = useSwiper();
  return (
    <div className="hidden lg:flex justify-center items-center gap-4 mt-8">
      <button
        type="button"
        className="z-10 hover:scale-[1.05] transition-transform ease-in-out duration-300 w-8 h-8 bg-[#687530] flex items-center justify-center rounded-full text-white"
        onClick={() => swiper.slidePrev()}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="16"
          viewBox="0 0 15 16"
          fill="none"
        >
          <path
            d="M0.893284 7.29289C0.502759 7.68342 0.502759 8.31658 0.893284 8.70711L7.25724 15.0711C7.64777 15.4616 8.28093 15.4616 8.67146 15.0711C9.06198 14.6805 9.06198 14.0474 8.67146 13.6569L3.0146 8L8.67146 2.34315C9.06198 1.95262 9.06198 1.31946 8.67146 0.928932C8.28093 0.538407 7.64777 0.538407 7.25725 0.928932L0.893284 7.29289ZM14.4004 7L1.60039 7L1.60039 9L14.4004 9L14.4004 7Z"
            fill="currentColor"
          />
        </svg>
      </button>
      <button
        type="button"
        className="z-10 hover:scale-[1.05] transition-transform ease-in-out duration-300 w-8 h-8 bg-[#687530] flex items-center justify-center rounded-full text-white"
        onClick={() => swiper.slideNext()}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="16"
          viewBox="0 0 15 16"
          fill="none"
        >
          <path
            d="M14.1067 8.70711C14.4972 8.31658 14.4972 7.68342 14.1067 7.29289L7.74276 0.928932C7.35223 0.538408 6.71907 0.538408 6.32854 0.928932C5.93802 1.31946 5.93802 1.95262 6.32854 2.34315L11.9854 8L6.32854 13.6569C5.93802 14.0474 5.93802 14.6805 6.32854 15.0711C6.71907 15.4616 7.35223 15.4616 7.74276 15.0711L14.1067 8.70711ZM0.599609 9H13.3996V7H0.599609V9Z"
            fill="currentColor"
          />
        </svg>
      </button>
    </div>
  );
}
