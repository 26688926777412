function Merlo() {
  return (
    <main className="bg-[#FFF7E8] flex flex-col font-dmsans text-center md:text-left">
      <section className="relative overflow-clip items-center h-[80vh] lg:h-[100vh]">
        <img
          className="w-full h-full object-cover"
          src="/photos/merlo.webp"
          alt="nuestro complejo"
        />
        <div className="absolute flex items-center justify-center top-0 z-20 bg-gradient-to-t from-[#00000099] to-transparent bg-opacity-10 w-full h-full">
          <h1 className="text-center text-[36px] lg:text-[44px] font-bold text-[#FAFAFF] font-timeless px-6">
            Bienvenidos a la
            {' '}
            <br />
            {' '}
            Villa de Merlo
          </h1>
        </div>
      </section>
      <section className="flex flex-col gap-8 md:gap-24 items-center container px-6 mx-auto py-8 md:py-16">
        <h2 className="text-[28px] leading-9 lg:text-4xl font-bold text-[#514036] font-timeless text-center">
          Lugares que se pueden visitar
        </h2>
        <p className="font-dmsans text-[#514036]">
          La ubicación de la Villa de Merlo en las Sierras de Los Comechingones, más factores que
          privilegian su extensión, califican a esta ciudad como el tercer microclima del mundo. Sus
          veranos cálidos e inviernos templados bajo cielos despejados en cualquier época del año,
          convierten a Merlo en un destino turístico de excelencia. La calidad del ozono en
          proporciones mayores a las normales produce una agradable sensación de bienestar; es una
          de las pocas regiones en el mundo con tan altos porcentajes de ionización negativa que
          produce efectos energizantes y estimulantes en el ánimo. Por ello, todo aquel que visita
          Merlo queda cautivado por su aire puro y renovador. Sus temperaturas agradables, con una
          media de 20°C, convierten a la Villa de Merlo en un lugar único, muy saludable, tranquilo
          y seguro.
        </p>
        <article className="flex flex-col md:flex-row w-full justify-between mx-auto gap-8 md:gap-16">
          <div className="flex flex-col gap-8">
            <h2 className="text-[28px] leading-9 lg:text-4xl font-bold text-[#514036] font-timeless">Cabalgatas</h2>
            <p className="font-dmsans text-[#514036]">
              En la Villa de Merlo se pueden hacer distintos tipos de cabalgatas: por simples
              senderos desde los que es posible apreciar la flora autóctona, hasta las cabalgatas al
              Filo de la Sierra, llegando incluso al Cerro Áspero, un antiguo pueblo minero
              abandonado después de la Segunda Guerra Mundial, actualmente está reacondicionado como
              refugio de montaña, que ofrece la posibilidad de acampar, pasar el día u hospedarse en
              el albergue.
            </p>
          </div>
          <img
            src="/photos/merlo/cabalgata.jpg"
            alt=""
            className="w-full md:w-1/2 lg:w-[45%] aspect-video rounded-md object-cover h-full"
          />
        </article>
        <article className="flex flex-col md:flex-row-reverse w-full justify-between mx-auto gap-8 md:gap-16">
          <div className="flex flex-col gap-8">
            <h2 className="text-[28px] leading-9 lg:text-4xl font-bold text-[#514036] font-timeless">Parapentismo</h2>
            <p className="font-dmsans text-[#514036]">
              La Villa de Merlo es especial para la práctica de este deporte, ya que el serrano
              tiene un promedio de 2000 metros de altura y dos plataformas de despegue. Subiendo por
              la Ruta Provincial N° 5 y pasando el Mirador del Sol se encuentran los dos sitios de
              partida: uno antes de llegar a la finalización de la ruta y el otro una vez que
              termina el pavimento en el filo de la sierra y después de recorrer unos 3 km hasta
              llegar a la confitería Mirador de los Cóndores. En ambos casos se realizan vuelos
              biplazas acompañados por instructores experimentados.
            </p>
          </div>
          <img
            src="/photos/merlo/parapente.jpg"
            alt=""
            className="w-full md:w-1/2 lg:w-[45%] aspect-video rounded-md object-cover h-full"
          />
        </article>
        <article className="flex flex-col md:flex-row w-full justify-between mx-auto gap-8 md:gap-16">
          <div className="flex flex-col gap-8">
            <h2 className="text-[28px] leading-9 lg:text-4xl font-bold text-[#514036] font-timeless">Tirolesa</h2>
            <p className="font-dmsans text-[#514036]">
              La Tirolesa (corrida de soga de una ladera a otro de la sierra) es una actividad que
              une la adrenalina y el vértigo con la tranquilidad serrana. En Merlo se realiza en El
              Salto del Tabaquillo y en Pasos Malos. Se recomienda enfáticamente recurrir a
              profesionales experimentados en este tema.
            </p>
          </div>
          <img
            src="/photos/merlo/tirolesa.jpg"
            alt=""
            className="w-full md:w-1/2 lg:w-[45%] aspect-video rounded-md object-cover h-full"
          />
        </article>
      </section>
      <section className="bg-[#687530] py-8 md:py-16 text-white">
        <div className="container px-6 mx-auto flex flex-col gap-8 md:gap-24">
          <article className="flex flex-col md:flex-row-reverse w-full justify-between mx-auto gap-8 md:gap-16">
            <div className="flex flex-col gap-8">
              <h2 className="text-[28px] leading-9 lg:text-4xl font-bold font-timeless">Arroyos</h2>
              <p className="font-dmsans">
                <strong>Arroyo El Molino:</strong>
                {' '}
                A 4 Km. Del centro de Merlo, por Ruta N° 5, en Rincón del
                Este. En el predio hay Confitería-Restaurante. Acceso al arroyo: gratuito. Hacia la
                sierra está el Salto del Tabaquillo, cascada de 15 metros de altura.
              </p>
              <p className="font-dmsans">
                <strong>Arroyo Pasos Malos:</strong>
                {' '}
                Subiendo por el camino pavimentado desde Avenida Dos Venados y seguir subiendo hasta
                el final del camino donde está la propiedad de la familia Godoy (se cobra el
                estacionamiento) y desde allí bajar al arroyo que tiene sus nacientes en las Sierras
                de los Comechingones. Se puede llegar a la Cascada Olvidada remontando el arroyo con
                un guía habilitado.
              </p>
            </div>
            <img
              src="/photos/merlo/arroyo.jpg"
              alt=""
              className="w-full md:w-1/2 lg:w-[45%] aspect-video rounded-md object-cover h-full"
            />
          </article>
          <article className="flex flex-col md:flex-row w-full justify-between mx-auto gap-8 md:gap-16">
            <div className="flex flex-col gap-8">
              <h2 className="text-[28px] leading-9 lg:text-4xl font-bold font-timeless">Mirador del Sol</h2>
              <p className="font-dmsans">
                Este es el primer punto panorámico del Camino al Filo Serrano. Se puede estacionar
                un vehículo o bicicletas. También se hacen salidas diarias en cuatriciclo. Aquí hay
                puestos de artesanías y productos regionales.
              </p>
            </div>
            <img
              src="/photos/merlo/mirador-del-sol.jpg"
              alt=""
              className="w-full md:w-1/2 lg:w-[45%] aspect-video rounded-md object-cover h-full"
            />
          </article>
        </div>
      </section>
      <section className="bg-[#FFF7E8] text-[#514036] flex flex-col gap-8 md:gap-24 container px-6 mx-auto py-8 md:py-16">
        <article className="flex flex-col md:flex-row-reverse w-full justify-between mx-auto gap-8 md:gap-16">
          <div className="flex flex-col gap-8">
            <h2 className="text-[28px] leading-9 lg:text-4xl font-bold font-timeless">
              Mirador de los Cóndores
            </h2>
            <p className="font-dmsans">
              Este mirador, aproximadamente a unos 1600 msnm, es el punto más alto del Camino al
              Filo Serrano. Una vez que finaliza el asfalto, se deben recorrer unos 2 kilómetros de
              camino consolidado en buen estado hasta llegar a un pórtico (pueden cobrar entrada).
              Aquí hay una confitería con baños públicos y una boutique con productos regionales.
              Las vistas desde este punto panorámico son impresionantes. También se realizan
              actividades de tirolesa, parapente, puentes colgantes, entre otras.
            </p>
          </div>
          <img
            src="/photos/merlo/mirador-de-los-condores.jpg"
            alt=""
            className="w-full md:w-1/2 lg:w-[45%] aspect-video rounded-md object-cover h-full"
          />
        </article>
        <article className="flex flex-col md:flex-row w-full justify-between mx-auto gap-8 md:gap-16">
          <div className="flex flex-col gap-8">
            <h2 className="text-[28px] leading-9 lg:text-4xl font-bold font-timeless">Algarrobo Abuelo</h2>
            <p className="font-dmsans">
              Es un hermoso parque en el cual su ejemplar más destacado es un impresionante
              algarrobo que se calcula tiene más de 1000 años de edad. Mide 6,13 m de circunferencia
              a 80cm sobre el suelo, se abre en cinco ramas muy gruesas, tres de las cuales ya tocan
              la tierra. En el parque hay un busto del poeta local Antonio Esteban Agüero, que
              escribió &ldquo La Cantata del Árbol &ldquo.
            </p>
          </div>
          <img
            src="/photos/merlo/algarrobo.jpg"
            alt=""
            className="w-full md:w-1/2 lg:w-[45%] aspect-video rounded-md object-cover h-full"
          />
        </article>
      </section>
      <section className="bg-[#514036] py-8 md:py-16 text-white">
        <div className="container px-6 mx-auto flex flex-col gap-8 md:gap-20">
          <article className="flex flex-col md:flex-row-reverse justify-end mx-auto gap-8 md:gap-40">
            <div className="flex flex-col gap-8">
              <h2 className="text-[28px] leading-9 lg:text-4xl font-bold font-timeless">
                La Reserva Mogote Bayo
              </h2>
              <p className="font-dmsans">
                La Reserva Mogote Bayo pertenece al Ministerio de Medio Ambiente de la Provincia de
                San Luis en gestión asociada con la Fundación Espacios Verdes (ONG ambiental). En la
                Reserva, podrás disfrutar de senderos serranos que atraviesan el faldeo de la sierra
                balconeando el valle y por el interior de la misma, disfrutando de un paseo por la
                Naturaleza. También pueden realizar el sendero del vía crucis, donde se llega a la
                cruz del Mogote Bayo, punto panorámico de todo el Valle. Y para los amantes de las
                visitas nocturnas, ofrecemos el recorrido por la sierra junto con los guardaparques,
                quienes te ofrecerán la mejor información de la Reserva y su historia.
              </p>
            </div>
            <img
              src="/photos/merlo/mogote-bayo.jpg"
              alt=""
              className="w-full md:w-1/2 lg:w-[45%] aspect-video rounded-md object-cover h-full"
            />
          </article>
          <article className="flex flex-col md:flex-row justify-start mx-auto gap-8 md:gap-40">
            <div className="flex flex-col gap-8">
              <h2 className="text-[28px] leading-9 lg:text-4xl font-bold font-timeless">Casino Flamingo</h2>
              <p className="font-dmsans">
                En una ubicación privilegiada, el Casino Flamingo es un ícono de la Villa de Merlo,
                provincia de San Luis. Poker, Black Jack, Dados, Ruleta tradicional, Ruleta
                electrónica y Tragamonedas en un ambiente de cuidada elegancia y excelente servicio,
                con su propio escenario para espectáculos musicales.
              </p>
            </div>
            <img
              src="/photos/merlo/casino-flamingo.jpg"
              alt=""
              className="w-full md:w-1/2 lg:w-[45%] aspect-video rounded-md object-cover h-full"
            />
          </article>
        </div>
      </section>
    </main>
  );
}

export default Merlo;
