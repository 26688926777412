import { ServicesListSimple } from '../components/ServicesList';

const POLITICAS_DE_CANCELACION = [
  'En caso de cancelación de la reserva, el dinero abonado en concepto de seña o pago estadía no se devuelve.',
  'El mismo queda como crédito para utilizarse  en una nueva estadía durante los próximos 6 meses. (SUJETO A DISPONIBILIDAD Y CON LA CORRESPONDIENTE ACTUALIZACION DE TARIFA)',
  'Las tarifas NO REEMBOLSABLES  no tienen cancelación flexible.',
  'Las cancelaciones y modificaciones tendrán validez únicamente enviadas por mail a reservas@dgregorio.com',
];

const POLITICAS_DE_CANCELACION_BAJA = [
  'Cancelación recibida hasta 7 días antes del ingreso, el 100% abonado queda disponible para una nueva reserva.',
  'Cancelación recibida hasta 48 horas antes del ingreso  tendrá una penalidad del valor equivalente a una noche.',
  'Cancelación recibida hasta 24  horas antes del ingreso se reconocerá solo el 50% de lo abonado.',
];

const POLITICAS_DE_CANCELACION_ALTA = [
  'Cancelación recibida hasta 30 días antes del ingreso, el 100% abonado queda disponible para una nueva reserva.',
  'Cancelación recibida hasta 20 días  antes del ingreso se reconocerá solo el 50% de lo abonado. Después de este límite generará el no reconocimiento del 100% de lo abonado.',
];

const PAUTAS_DE_RESERVACION = [
  'Solo se admiten reservas a mayores de 18 años.',
  'Los mayores de 2 años son considerados un pasajero mas.',
  'Admitimos mascotas falderas (razas similares a caniche toy) con aviso anticipado y costo adicional para reservas en cabañas y apart. Por otras razas consultar antes de reservar.',
  'El ingreso deberá realizarse en nuestra recepción presentando su documento de identidad y llenando una ficha de pasajeros',
  'NO ESTA PERMITIDO RECIBIR VISITAS.',
  'La limpieza  debe solicitarse en administración antes de las 11hs, debiendo dejar la unidad sin gente ni mascotas. Este servicio no comprende el lavado de la vajilla.',
  'El recambio de sabanas, toallón de pileta, mantel, repasador se hará cada 3 noches, (4to día de alojamiento). Toalla y toallón de mano recambio cada 2 días.',
  'Al concluir su estadía en nuestro complejo, deben entregar la llave y los controles en la recepción. Horario de salida es hasta las 10hs.',
  'El desayuno es opcional y con costo adicional (consultar) Se lleva a la unidad Disponible de 8:30 a 10:30 hs',
];

function PoliticasDeCancelacion() {
  return (
    <main className="flex flex-col bg-[#FFF7E8]">
      <div className="container px-6 mx-auto pt-40">
        <h1 className="text-center text-4xl lg:text-5xl text-[#514036] font-timeless font-bold">Politicas de cancelación</h1>
        <section className="mt-16 max-w-prose flex flex-col mx-auto">
          <ServicesListSimple services={POLITICAS_DE_CANCELACION} />
          <h3 className="text-[#514036] font-timeless font-bold py-8">
            Cancelación Flexible temporada baja
          </h3>
          <ServicesListSimple services={POLITICAS_DE_CANCELACION_BAJA} />
          <h3 className="text-[#514036] font-timeless font-bold py-8"> Cancelación Flexible Julio - Enero - Febrero y Feriados</h3>
          <ServicesListSimple services={POLITICAS_DE_CANCELACION_ALTA} />
        </section>
        <h2 className="text-center text-4xl lg:text-5xl text-[#514036] font-timeless font-bold pt-20">Pautas de reservación y estadía</h2>
        <section className="mt-16 max-w-prose flex flex-col mx-auto">
          <ServicesListSimple services={PAUTAS_DE_RESERVACION} />
        </section>
      </div>
    </main>
  );
}

export default PoliticasDeCancelacion;
