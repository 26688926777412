import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <main className="flex flex-col justify-center items-center h-[100vh] bg-[#FFF7E8]">
      <h1 className="text-4xl font-semibold text-red-600 font-timeless">404 - Página no encontrada</h1>
      <p className="mt-4 text-gray-600 font-dmsans">La pagina que buscas no existe</p>
      <Link
        className="text-white bg-[#7C8944] transition-colors ease-in-out duration-[400ms] hover:bg-[#4F5923] py-3 px-4 rounded-[10px] mt-8 font-dmsans font-bold"
        to="/"
      >
        Volver a la página principal
      </Link>
    </main>
  );
}

export default NotFound;
