import { Link } from 'react-router-dom';

export default function Footer() {
  const actualYear = new Date().getFullYear();
  return (
    <footer className="bg-[#FFF7E8]">
      <div className="container mx-auto px-6 py-16">
        <div className="border-t border-t-[#D4D2E3] flex flex-col lg:flex-row justify-between pt-16">
          <div className="flex flex-col gap-3">
            <h2 className="text-lg font-timeless font-bold text-[#514036]">¿Cómo llegar al complejo?</h2>
            <div className="flex items-center gap-2">
              <img src="/icons/location-on.svg" alt="icono localizacion" />
              <Link
                to="https://maps.app.goo.gl/GAJeGRkJbPHXwLZKA"
                target="_blank"
                className="text-sm font-normal font-dmsans text-[#2D2D2D]"
              >
                Neptuno 455, Villa de Merlo, San Luis, Argentina
              </Link>
            </div>
            <div className="bg-gray-500 w-full lg:w-[700px] h-64 lg:h-full mt-3 rounded-[10px] overflow-hidden">
              <iframe title="google-maps" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d26965.413840881287!2d-64.994837!3d-32.347387!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95d2e1540920645d%3A0xa39080a3056b123b!2sComplejo%20Don%20Gregorio!5e0!3m2!1ses!2sar!4v1695404235215!5m2!1ses!2sar" width="100%" height="100%" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
            </div>
          </div>
          <div className="flex flex-col mt-12">
            <img src="/logo-color.svg" alt="logo don gregorio" height={67} width={160} />
            <h2 className="text-lg font-bold text-[#514036] font-timeless mt-5">Contactanos</h2>
            <div className="flex flex-col gap-3 font-dmsans mt-3">
              <div className="flex gap-3">
                <img src="/icons/phone-green.svg" alt="phone icon" />
                <span className="text-sm text-[#2D2D2D]">02656 473367</span>
              </div>
              <div className="flex gap-3">
                <img src="/icons/whatsapp-green.svg" alt="whatsapp icon" />
                <Link
                  to="https://wa.me/5492664007009?text=Hola!%20Quisiera%20realizar%20una%20consulta..."
                  target="_blank"
                  className="text-sm text-[#2D2D2D]"
                >
                  +54 9 266 4007009
                </Link>
              </div>
              <div className="flex gap-3">
                <img src="/icons/mail-green.svg" alt="mail icon" />
                <Link
                  to="mailto:reservas@dgregorio.com"
                  className="text-sm text-[#2D2D2D]"
                >
                  reservas@dgregorio.com
                </Link>
              </div>
            </div>
            <h2 className="text-xl font-bold text-[#514036] mt-3">Seguinos</h2>
            <div className="flex gap-6 mt-3">
              <Link to="https://www.instagram.com/dongregoriocomplejo/" target="_blank">
                <img src="/icons/ig-green.svg" alt="logo instagram" />
              </Link>
              <Link to="https://www.facebook.com/dongregoriosanluis/" target="_blank">
                <img src="/icons/fb-green.svg" alt="logo facebook" />
              </Link>
            </div>
          </div>
        </div>
        <div className="flex mt-8 font-dmsans justify-between">
          <p className="text-lg text-[#767494]">
            Don Gregorio ©
            {' '}
            {actualYear}
          </p>
        </div>
      </div>
    </footer>
  );
}
